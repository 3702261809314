<template>
  <el-form-item class="annex" prop="annex">
    <el-upload
      :action="imgUpload.host"
      list-type="picture-card"
      auto-upload
      :file-list="fileList"
      :on-change="handleChange"
      :limit="10"
      multiple
      accept="image/png,image/jpg,image/bmp,image/jpeg"
      :before-upload="beforeUpload"
      :data="imgUpload"
      class="upload-comp"
      :http-request="uploadRequest"
    >
      <div slot="default" class="upload-text">
        <div class="inner">
          <i class="el-icon-circle-plus-outline"></i>
          <p>支持jpg/png/bmp<br/>大小2M以内</p>
        </div>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </el-form-item>
</template>

<script>
import md5 from 'md5'
import { v4 as uuidv4 } from 'uuid'
import { formatTimesFileName } from '@/utils/timeDate'
import axios from 'axios'
export default {
  data(){
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      imgUpload: {
        //商品名字
        name: '',
        //路径
        key: "",
        OSSAccessKeyId: "",
        success_action_status:200,
        expire : "",
        host : "https://hq-tianyi.oss-cn-shenzhen.aliyuncs.com",
        policy : '',
        signature : '',
        fileKey: md5((this.$route.query.orderId || '') + '' + Date.now(), 8).substr(0, 10) + '_',//为了分离上传文件索引,防止重复
      },
      realFileList: []
    }
  },
  computed: {
    headers(){
      return{
        token: localStorage.getItem("onlineSchoolToken")
      }
    }
  },
  methods: {

    uploadRequest(op) {
      this.myupload(
        op.file,
        res => {
          let temp = {
            name: res.attachment,
            url: res.aliyunAddress
          }
          // 成功后的回调，把结果返回，并且把上传列表的状态改变，打上成功的√
          op.onSuccess(temp)
        },
        err => {
          console.log(err)
        },
        res => {
        // 处理进度条的东西，把进度数据传进去实现进度条走动，传参res应该是这样的类型{percent: 48}
          op.onProgress(res)
        }
      )
        // console.log(param, "666666666666");
        // let fileObj = param.file;
        // let form = new FormData();
        // form.append('file', fileObj);
        // form.append('dir', param.data.dir)
        // form.append('expire', param.data.expire)
        // form.append('fileKey', param.data.fileKey)
        // form.append('host', param.data.host)
        // form.append('key', param.data.key)
        // form.append('name', param.data.name)
        // form.append('policy', param.data.policy)
        // form.append('signature', param.data.signature)
        // form.append('success_action_status', param.data.success_action_status)
        // form.append('uid', param.data.uid)
        // axios.post(`https://hq-tianyi.oss-cn-shenzhen.aliyuncs.com`, form, {
        //   process: function (event) {
        //     param.file.percent = event.loaded / event.total * 100;
        //     param.onprogress(param.file);
        //   }
        // }).then(res => {
        //   // this.uploadSuccess(res);
        // }).catch(res => {
        //   // this.uploadError();
        // });
      },
    async getSignture(){
      //获取凭证
      let {code, data} = await this.$fetch("ossSigniture", {code: 'tianyi'});
      if(code === 200){
        Object.assign( this.imgUpload, {
          dir: data.dir,
          key: `${data.dir}\${filename}`,
          policy: data.policy,
          OSSAccessKeyId: data.accessid,
          expire: data.expire,
          host: data.host,
          success_action_status: 200,
          signature: data.signature
        })
      }
    },
    async beforeUpload(file, index){
      let allowExt = ["jpg", "jpeg", "bmp", "png"];
      let isAllowUpload = allowExt.some( item=>{
          return file.type.indexOf( item ) > -1;
      })
      if (!isAllowUpload) {
        this.$message.error('上传凭证只允许 jpg、jpeg、bmp、png 格式!');
      }

      const isLt2M = file.size / 1024 / 1024 > 2;
      if (isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
        return false;
      }
      const len = file.name.split('.')
      // this.imgUpload.name = file.name;
      this.imgUpload.name = file.uid + '.' + len[len.length - 1]
      this.imgUpload.uid = file.uid
      // await this.getSignture();
      return isAllowUpload
      
    },
    handleChange(file, fileList){
      console.log('触发了');
      this.fileList = fileList
    },
    handleRemove(file) {
      const index = this.fileList.findIndex(item => item.uid === file.uid)
      index > -1 && this.fileList.splice(index, 1)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getAllFiles(){
      let files = []
      this.fileList.forEach(item =>{
        if(item.status === 'success'){
          let arr = this.realFileList.filter(i => item.uid === i.uid)
          files.push({
            fileName: arr[0].name,
            fileSuffix: arr[0].name.split('.').pop().toLowerCase(),
            fileUrl: `${this.imgUpload.host}/${this.imgUpload.dir}${arr[0].name}`
            // fileName: item.name,
            // fileSuffix: item.name.split('.').pop().toLowerCase(),
            // fileUrl: `${this.imgUpload.host}/${this.imgUpload.dir}${item.name}`
          })
        }
      })
      return files
    },
    myupload(file, successCallback = new Function(), errCallBack = new Function(), progressCallback = new Function()) {
      let fileName = file.name
      // options = options || {}
      this.$fetch("ossSigniture", {code: 'tianyi'})
      .then(res => {
        let obj = res.data
        let config = {}
        config.host = obj['host']
        config.policyBase64 = obj['policy']
        config.accessid = obj['accessId']
        config.signature = obj['signature']
        config.expire = parseInt(obj['expire'])
        config.callbackbody = obj['callback']
        config.dir = obj['dir']
        this.imgUpload.dir = obj.dir

        const random = this.getRandomNum(10000, 99999)
        const len = file.name.split('.')
        const time = formatTimesFileName(new Date()).replace(/-/g, '')
        const newName = `${time}${random}.${len[len.length-1]}`
        const renameFile = new File([file], newName, {type: file.type, uid: file.uid})
        this.realFileList.push({uid: file.uid, name: newName})
        let form = new FormData(),
        key = config.dir + md5((this.$route.query.orderId || '') + '' + Date.now(), 8).substr(0, 10) + '_'
        form.append('name', newName)
        form.append('key', `${obj.dir}\${filename}`)
        form.append('policy', obj.policy)
        form.append('OSSAccessKeyId', obj.accessid)
        form.append('expire', obj.expire)
        form.append('host', obj.host)
        form.append('success_action_status', 200)
        form.append('signature', obj.signature)
        form.append('file', renameFile)
        if (config.host.indexOf('http:') > -1) {
          var protocol = window.location.protocol || 'http:'
          var subUrl = config.host.substring(5, config.host.length)
          config.host = protocol + subUrl
        }
        axios({
          url: config.host,
          method: 'POST',
          data: form,
          processData: false,
          cache: false,
          contentType: false,
          onUploadProgress: function(progressEvent) {
            if (progressEvent.lengthComputable) {
              let percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
              progressCallback({ percent: percent })
            }
          }
        })
          .then(() => {
            let size = file.size > 1000000 ? parseFloat(file.size / 1000000).toFixed(2) + 'M' : parseFloat(file.size / 1000).toFixed(2) + 'KB'
            successCallback({
              attachment: fileName,
              aliyunAddress: key,
              size: size
            })
          })
          .catch(err => {
            errCallBack(err)
          })
      })
      .catch(err => {
        errCallBack(err)
        // console.log(err)
      })
    },
    getRandomNum(Min,Max) {
      let Range = Max - Min;
      let Rand = Math.random();
      return(Min + Math.round(Rand * Range));
    },
  }
}
</script>

<style lang="scss" scoped>
.annex{
  .upload-comp{
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  .upload-text{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #999;
    font-size: 14px;
    &:before{
      position: absolute;
      left: 18px;
      top: 12px;
      content: "附件";
      height: 1.5em;
      line-height: 1.5em;
    }
    .inner{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    p{
      line-height: 1.5em;
      height: 1.5em;
    }
  }
  ::v-deep .el-upload-list{
    padding-left: 0;
    white-space: nowrap;
    flex: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 11px;
    height: 156px;
    display: flex;
    li{
      margin-right: 24px;
      width: 230px;
      height: 130px;
      overflow: visible;
      transition: none;
      flex-shrink: 0;
    .el-upload-list__item-thumbnail{
        object-fit: contain;
        height: auto;
        max-height: 100%;
        width: 98%;
      }
      .el-upload-list__item-status-label{
        display: none;
        .el-icon-close-tip{
          display: none;
        }
      }
      .el-upload-list__item-actions{
        display: inline-block;
        opacity: 1;
        background-color: transparent;
        position: absolute;
        right: -10px;
        top: -10px;
        left: unset;
        width: 20px;
        height: 20px;
        display: flex;
        .el-upload-list__item-delete{
          display: inline-block;
          line-height: 1;
          .el-icon-delete{
            color: #fff;
            background-color: #F54955;
            border-radius: 50%;
            &:before{
              content: "\e78d";
            }
          }
        }
      }
    }
  }
  ::v-deep .el-upload--picture-card{
    position: relative;
    margin-right: 24px;
    width: 282px;
    height: 130px;
    flex-shrink: 0;
    border-color:#E1E4EB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
  }
}
</style>