import { render, staticRenderFns } from "./annex.vue?vue&type=template&id=33a19ade&scoped=true&"
import script from "./annex.vue?vue&type=script&lang=js&"
export * from "./annex.vue?vue&type=script&lang=js&"
import style0 from "./annex.vue?vue&type=style&index=0&id=33a19ade&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33a19ade",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\qikecheng_new\\allnet_crm\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33a19ade')) {
      api.createRecord('33a19ade', component.options)
    } else {
      api.reload('33a19ade', component.options)
    }
    module.hot.accept("./annex.vue?vue&type=template&id=33a19ade&scoped=true&", function () {
      api.rerender('33a19ade', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/after-sale/drop-out/components/annex.vue"
export default component.exports