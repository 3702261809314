var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { staticClass: "annex", attrs: { prop: "annex" } },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-comp",
          attrs: {
            action: _vm.imgUpload.host,
            "list-type": "picture-card",
            "auto-upload": "",
            "file-list": _vm.fileList,
            "on-change": _vm.handleChange,
            limit: 10,
            multiple: "",
            accept: "image/png,image/jpg,image/bmp,image/jpeg",
            "before-upload": _vm.beforeUpload,
            data: _vm.imgUpload,
            "http-request": _vm.uploadRequest
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "upload-text",
              attrs: { slot: "default" },
              slot: "default"
            },
            [
              _c("div", { staticClass: "inner" }, [
                _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                _c("p", [
                  _vm._v("支持jpg/png/bmp"),
                  _c("br"),
                  _vm._v("大小2M以内")
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [_c("img", { attrs: { width: "100%", src: _vm.dialogImageUrl } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }